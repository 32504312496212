.App {
  text-align: center;
}
.MuiFormHelperText-root {
  color: red !important;
}

.ErrMsg {
  color: red !important;
}
